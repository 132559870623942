import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';

const Screen = ({ form, screenIndex, wizard }) => {

  useScreen({ isInvalid: false, screenIndex, wizard });

  return (
    <Fieldset legend="Other Vehicle Owner Information">
      <Row>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerFirstName}
            id="otherVehicleOwnerFirstName"
            label="First name"
            touched={form?.formState.touchedFields.otherVehicleOwnerFirstName}
            {...form?.register('otherVehicleOwnerFirstName', {
              onBlur: () => form?.trigger('otherVehicleOwnerFirstName'),
            })}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerMiddleName"
            label="Middle name"
            {...form?.register('otherVehicleOwnerMiddleName')}
          />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerLastName}
            id="otherVehicleOwnerLastName"
            label="Last name"
            touched={form?.formState.touchedFields.otherVehicleOwnerLastName}
            {...form?.register('otherVehicleOwnerLastName', {
              onBlur: () => form?.trigger('otherVehicleOwnerLastName'),
            })}
          />
        </Col>
      </Row>
      <Input
        autoComplete="off"
        id="otherVehicleOwnerStreetAddress"
        label="Street address"
        {...form?.register('otherVehicleOwnerStreetAddress')}
      />
      <Row>
        <Col xs={12} sm={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerCity"
            label="City"
            {...form?.register('otherVehicleOwnerCity')}
          />
        </Col>
        <Col xs={12} sm={4}>
          <Select
            autoComplete="off"
            id="otherVehicleOwnerState"
            label="State"
            {...form?.register('otherVehicleOwnerState')}
          >
            <option disabled value="" />
            {usStates.map((state) => (
              <option
                key={state}
                value={state}
              >
                {state}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={12} sm={4}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerZip"
            label="Zip Code"
            {...form?.register('otherVehicleOwnerZip')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerHomePhone"
            label="Home phone number"
            type="tel"
            {...form?.register('otherVehicleOwnerHomePhone')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerCellPhone"
            label="Cell phone number"
            type="tel"
            {...form?.register('otherVehicleOwnerCellPhone')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="otherVehicleOwnerWorkPhone"
            label="Work phone number"
            type="tel"
            {...form?.register('otherVehicleOwnerWorkPhone')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            error={form?.formState.errors.otherVehicleOwnerEmail}
            id="otherVehicleOwnerEmail"
            label="Email"
            touched={form?.formState.touchedFields.otherVehicleOwnerEmail}
            type="email"
            {...form?.register('otherVehicleOwnerEmail', {
              onBlur: () => form?.trigger('otherVehicleOwnerEmail'),
            })}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default Screen;
