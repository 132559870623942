import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';

import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {
  
  const otherVehicleDriversSameAsVehicleOwner = form?.watch('otherVehicleDriversSameAsVehicleOwner');
  const otherVehicleDriversDidReceiveTicket = form?.watch('otherVehicleDriversDidReceiveTicket');
  const otherVehicleDriversInjured = form?.watch('otherVehicleDriversInjured');

  const isInvalid = useMemo(
    () => (
      !otherVehicleDriversSameAsVehicleOwner
    ),
    [
      otherVehicleDriversSameAsVehicleOwner
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Other Vehicle Driver Information
">
        <Row>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Same as Vehicle Owner?"
              name="otherVehicleDriversSameAsVehicleOwner"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversSameAsVehicleOwner === "No" &&
            (
              <>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.otherVehicleDriversFirstName}
                      id="otherVehicleDriversFirstName"
                      label="First name"
                      touched={form?.formState.touchedFields.otherVehicleDriversFirstName}
                      {...form?.register('otherVehicleDriversFirstName', {
                        onBlur: () => form?.trigger('otherVehicleDriversFirstName'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      id="otherVehicleDriversMiddleName"
                      label="Middle name"
                      {...form?.register('otherVehicleDriversMiddleName')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.otherVehicleDriversLastName}
                      id="otherVehicleDriversLastName"
                      label="Last name"
                      touched={form?.formState.touchedFields.otherVehicleDriversLastName}
                      {...form?.register('otherVehicleDriversLastName', {
                        onBlur: () => form?.trigger('otherVehicleDriversLastName'),
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                <Col xs={12} sm={12}>
                  <Input
                    autoComplete="off"
                    id="otherVehicleDriversStreetAddress"
                    label="Street address"
                    {...form?.register('otherVehicleDriversStreetAddress')}
                  />
                  </Col>
                    <Col xs={12} sm={4}>
                      <Input
                        autoComplete="off"
                        id="otherVehicleDriversCity"
                        label="City"
                        {...form?.register('otherVehicleDriversCity')}
                      />
                    </Col>
                    <Col xs={12} sm={4}>
                      <Select
                        autoComplete="off"
                        id="otherVehicleDriversState"
                        label="State"
                        {...form?.register('otherVehicleDriversState')}
                      >
                        <option disabled value="" />
                        {usStates.map((state) => (
                          <option
                            key={state}
                            value={state}
                          >
                            {state}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={12} sm={4}>
                      <Input
                        autoComplete="off"
                        id="otherVehicleDriversZip"
                        label="Zip Code"
                        {...form?.register('otherVehicleDriversZip')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="otherVehicleDriversHomePhone"
                        label="Home phone number"
                        type="tel"
                        {...form?.register('otherVehicleDriversHomePhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="otherVehicleDriversCellPhone"
                        label="Cell phone number"
                        type="tel"
                        {...form?.register('otherVehicleDriversCellPhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="otherVehicleDriversWorkPhone"
                        label="Work phone number"
                        type="tel"
                        {...form?.register('otherVehicleDriversWorkPhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        error={form?.formState.errors.otherVehicleDriversEmail}
                        id="otherVehicleDriversEmail"
                        label="Email"
                        touched={form?.formState.touchedFields.otherVehicleDriversEmail}
                        type="email"
                        {...form?.register('otherVehicleDriversEmail', {
                          onBlur: () => form?.trigger('otherVehicleDriversEmail'),
                        })}
                      />
                    </Col>

                </Row>
              </>
            )}
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.otherVehicleDriversDateOfBirth}
              id="otherVehicleDriversDateOfBirth"
              label="Date of birth"
              type="date"
              {...form?.register('otherVehicleDriversDateOfBirth', {
                onBlur: () => form?.trigger('otherVehicleDriversDateOfBirth'),
                // required: 'This field is required.',
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              id="otherVehicleDriversLicenseNumber"
              label="Drivers License Number"
              {...form?.register('otherVehicleDriversLicenseNumber')}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Select
              autoComplete="off"
              id="otherVehicleDriversLicenseState"
              label="Drivers License State"
              {...form?.register('otherVehicleDriversLicenseState')}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Did this person receive a ticket?"
              name="otherVehicleDriversDidReceiveTicket"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
              <span value="Unknown">Unknown</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversDidReceiveTicket === "Yes" &&
            <Col xs={12} sm={12}>
              <Textarea
                id="otherVehicleDriversTicketReason"
                label="Ticket Reason"
                maxLength={maxLength}
                name="otherVehicleDriversTicketReason"
                rows={3}
                form={form}
                {...form?.register('otherVehicleDriversTicketReason')}
              />

            </Col>}
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Was this person injured?"
              name="otherVehicleDriversInjured"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {otherVehicleDriversInjured ==="Yes" && 
          (
            <Row>
            <Col xs={12} sm={6}>
              <Textarea
                id="otherVehicleDriversInjuryDescription"
                label="Describe Injuries"
                maxLength={maxLength}
                name="otherVehicleDriversInjuryDescription"
                rows={3}
                form={form}
                {...form?.register('otherVehicleDriversInjuryDescription')}
              />

            </Col>
            <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Did they seek medical treatment?"
              name="otherVehicleDriversDidSeekMedical"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          </Row>

          )}

        </Row>

      </Fieldset>
    </>
  );
};

export default Screen;
