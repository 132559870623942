import { Row, Container } from "react-bootstrap";
import React, { useEffect } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import clsx from "clsx";
import Icon from './helper/Icon';




const MapBlock = ({ data, className, mapDatas }) => {
    var mapData = [];
    mapDatas?.map(function(location) {
        mapData.push({ id: "US-"+location.attributes.state, body:location.attributes.body, services: location.attributes.services, selected: true})
    });

    useEffect(() => {
        // Create map instance
        var chart = am4core.create("chartdiv", am4maps.MapChart);
        chart.seriesContainer.draggable = false;
        chart.seriesContainer.resizable = false;
        chart.chartContainer.wheelable = false;

        chart.maxZoomLevel = 1;



        // Set map definition
        chart.geodata = am4geodata_usaLow;

        // Set projection
        chart.projection = new am4maps.projections.AlbersUsa();


        // Create map polygon series
        var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
        polygonSeries.exclude = ["US-AK", "US-HI"];

        //Set min/max fill color for each area


        // Make map load polygon data (state shapes and names) from GeoJSON
        polygonSeries.useGeodata = true;

        // Set heatmap values for each state
        polygonSeries.data = mapData;

        polygonSeries.fill = am4core.color("#3BAEC9");
        // polygonSeries.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        


        // Configure series tooltip
        var polygonTemplate = polygonSeries.mapPolygons.template;
        // polygonTemplate.tooltipText = "{body}: {value}";
        polygonTemplate.tooltipHTML = `<div><h4 style="color: #3BAEC9;">{name}</h4><p style="font-size: 16px">{body}</p></div>`;

        // Use an adapter to iterate through the nested array and provide formatted HTML
        polygonTemplate.adapter.add("tooltipHTML", function(html, target) {
          if (
            target.tooltipDataItem.dataContext &&
            target.tooltipDataItem.dataContext.services &&
            target.tooltipDataItem.dataContext.services.length
          ) {
            const serviceKey = 
            {
              autoInsurance: "Personal Auto Insurance",
              commercialAutoInsurance: "Commercial Auto Insurance",
              homeInsurance: "Home Insurance",
              mobileHomeInsurance: "Mobile Homeowner’s Insurance"

            };
            var iconCol;
            iconCol = "";
            target.tooltipDataItem.dataContext.services.forEach(service => {
              iconCol += `<div class="col col-md-auto" style="text-align: center;">${Icon(service.service)}<p style="font-size: 12px; min-height: 24px; text-align: center;">${serviceKey[service.service]}</p></div>`
            });
            html += `
            <div class="row">
              ${iconCol}
            </div>`;
          }
          return html;
        });
        polygonSeries.tooltip.label.wrap = true;
        polygonSeries.tooltip.label.width = 400;
        polygonSeries.tooltip.getFillFromObject = false;
        polygonSeries.tooltip.background.fill = am4core.color("#ffffff")
        polygonSeries.tooltip.autoTextColor = false;
        polygonSeries.tooltip.label.fill = am4core.color("#657687");
        polygonSeries.tooltip.background.fillOpacity = 1;
        polygonSeries.tooltip.background.stroke = am4core.color("#3BAEC9");

        // var activeState = polygonSeries.states.create("active");
        // activeState.properties.fill = am4core.color("black");




        polygonTemplate.exclude = ["US-UT", "US-AZ"];
        // polygonSeries.showTooltipOn = "hit";





        polygonTemplate.nonScalingStroke = true;
        polygonTemplate.exclude = ["US-UT", "US-AZ"];

        polygonTemplate.cursorOverStyle = am4core.MouseCursorStyle.pointer;
        polygonTemplate.strokeWidth = 0.5;
        polygonTemplate.fill = am4core.color("#F5F5F5");
        polygonTemplate.stroke = am4core.color("#95daea");
        polygonTemplate.strokeWidth = "0.5px";
        polygonTemplate.showTooltipOn = "hit";
        polygonTemplate.tooltipPosition = "fixed";
        polygonTemplate.adapter.add("fill", function(fill, target) {
            if (target.dataItem.dataContext && target.dataItem.dataContext.selected) {
              return am4core.color("#3BAEC9");
            }
            return fill;
          });

        // Create hover state and set alternative fill color
        var hs = polygonTemplate.states.create("click");
        hs.properties.fill = am4core.color("#3c5bdc");
        hs.properties.fill = am4core.color("#3c5bdc");

        return () => {
          chart && chart.dispose();
        };
      });

  return (
    <section
      className={clsx('MapBlock', className)}
    >
    <Container fluid >
      <Row style={{ backgroundColor: `#95DAEA` }}>
      <Row className="HeaderRow">{data.header && <h1>{data.header}</h1>}</Row>
      <div id="chartdiv" style={{ width: "100%", height: "700px"}}></div>
      </Row>
    </Container>
    </section>
  );
};

export default MapBlock;
