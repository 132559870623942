import * as yup from 'yup';

const schema = {
  policyHolderFirstName: yup.string().default('').required('This field is required'),
  policyHolderMiddleName: yup.string().default(''),
  policyHolderLastName: yup.string().default('').required('This field is required'),
  policyHolderStreetAddress: yup.string().default(''),
  policyHolderCity: yup.string().default(''),
  policyHolderState: yup.string().default(''),
  policyHolderZip: yup.string().default(''),
  policyHolderHomePhone: yup.string().default(''),
  policyHolderCellPhone: yup.string().default(''),
  policyHolderWorkPhone: yup.string().default(''),
  policyHolderEmail: yup.string().default('').email('Please enter a valid email address.'),
};

export default schema;
