import * as yup from 'yup';

const schema = {
  otherVehicleOwnerFirstName: yup.string().default(''),
  otherVehicleOwnerMiddleName: yup.string().default(''),
  otherVehicleOwnerLastName: yup.string().default(''),
  otherVehicleOwnerStreetAddress: yup.string().default(''),
  otherVehicleOwnerCity: yup.string().default(''),
  otherVehicleOwnerState: yup.string().default(''),
  otherVehicleOwnerZip: yup.string().default(''),
  otherVehicleOwnerHomePhone: yup.string().default(''),
  otherVehicleOwnerCellPhone: yup.string().default(''),
  otherVehicleOwnerWorkPhone: yup.string().default(''),
  otherVehicleOwnerEmail: yup.string().default('').email('Please enter a valid email address.'),
};

export default schema;
