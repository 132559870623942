import React, { useCallback, useState } from 'react';
import * as yup from 'yup';
import { noop, startCase } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Wizard from '../../../../../Wizard/Wizard';
import useScreensForPersonalAuto from './components/PersonalAuto/hooks/useScreensForPersonalAuto';
import axios, { AxiosError } from 'axios';
import useScreensForManufacturedHomeowner from './components/ManufacturedHomeowner/hooks/useScreensForManufacturedHomeowner';
import emailjs from '@emailjs/browser';
import * as ReactDOMServer from 'react-dom/server';
const hooksByEndpoint = {
  'personal-auto': useScreensForPersonalAuto,
  'manufactured-homeowner': useScreensForManufacturedHomeowner,
};

const Form = ({ className, endpoint }) => {

  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');
  const [formSubmittedWarning, setFormSubmittedWarning] = useState('');
  const [formError, setFormError] = useState(false);

  const useScreens = hooksByEndpoint[endpoint] || noop;
  const screens = useScreens() || [];
  const rootUrl = process.env.REACT_APP_API_URL;
  const xApiKey = process.env.REACT_APP_X_API_KEY;

  const schema = yup.object().shape(
    screens
      .map(({ schema: s }) => s)
      .reduce((x, s) => ({ ...x, ...s }), {})
  );

  const { handleSubmit, ...form } = useForm({

    defaultValues: schema.cast(),
    resolver: yupResolver(schema, {
      abortEarly: false,
      stripUnknown: true,
    }),
  });
  const tableGenerator = (data,i,tableTitle) => {
    return (
      <table style={{borderCollapse: 'collapse', width: '100%'}} border='1'>
        <colgroup>
          <col style={{width: '50%'}} />
          <col style={{width: '50%'}} />
        </colgroup>
        <tbody>
          <tr>
            <td style={{backgroundColor: 'rgb(45, 194, 107)'}} colSpan='2'><span
              style={{fontSize: '24pt'}}>{tableTitle} #{i+1} Information</span></td>
          </tr>
          {Object.entries(data).map(([key, value]) => (
            <tr key={key}>
              <td>{startCase(key)}</td>
              <td>{value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  const onSubmit = useCallback(
    (data) => {
      if (endpoint === 'manufactured-homeowner') {
        data?.claimants?.map((claimant, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(tableGenerator(claimant,i, 'Claimant'));
          var key = `claimTable${i}`;
          data[key] = claim;
        });
        console.log(data);




        //   data.emailTo = 'reed@thecollective.io'
        // emailjs.send('service_95ghn9d', 'template_exlr0cy', data, 'user_yjC6L95jqEW9krwJaHPTB')
        //   .then((result) => {
        //     setFormSubmitted(true);
        //     setFormSubmittedMessage('Thank you for contact us we will be in touch with you soon!')
        //   }, (error) => {
        //     setFormSubmitted(true);
        //     setFormSubmittedMessage('There was an error submitting your form. Please refresh the page and try again.')
        //   });


      } else if (endpoint === 'personal-auto') {

        data?.passengers?.map((passenger, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(tableGenerator(passenger,i, 'Passenger'));
          var key = `passengerTable${i}`;
          data[key] = claim;
        });
        data?.passengersClaimant?.map((passenger, i) => {
          var claim = ReactDOMServer.renderToStaticMarkup(tableGenerator(passenger,i, 'Other Vehicle Passenger'));
          var key = `passengerClaimantTable${i}`;
          data[key] = claim;
        });
        console.log(data);
        /*
        var submittedData = {
          "insured": {
            "policy_number": data.policyNumber,
            "vehicle": {
              "year": data.vehicleYear,
              "make": data.vehicleMake,
              "model": data.vehicleModel,
              "vin": data.vehicleVinNumber,
              "color": data.vehicleColor,
              "damage_info": {
                "is_damaged": data.isVehicleDamaged === "Yes" ? true : false,
                "damage_description": data.vehicleDamageDescription,
                "unrelated_prior_damage": data.unrelatedPriorDamage,
                "impact_location": data.pointOfImpact
              },
              "license_plate": data.licensePlateNumber,
              "is_drivable": data.isVehicleDrivable === "Yes" ? true : false,
              "use_type": data.vehicleDriversPurposeOfUse,
              "garage_info": {
                "location": {
                  "address": data.vehicleLocationStreetAddress,
                  "city": data.vehicleLocationCity,
                  "state": data.vehicleLocationState,
                  "zip_code": data.vehicleLocationZip
                },
                "name": data.businessName,
                "phone_number": data.businessPhone
              },
              "owner": {
                "first_name": data.policyHolderFirstName,
                "last_name": data.policyHolderLastName,
                "address": {
                  "address": data.policyHolderStreetAddress,
                  "city": data.policyHolderCity,
                  "state": data.policyHolderState,
                  "zip_code": data.policyHolderZip
                },
                "medical_info": {
                  "date_of_birth": "2000-02-29",
                  "sex": "M",
                  "is_injured": true,
                  "injuries_description": "Broken leg",
                  "sought_medical_treatment": true
                },
                "ticket_info": {
                  "is_issued": true,
                  "reason": "Drunk driving"
                },
                "contact_info": {
                  "ok_to_call": data.policyHolderHomePhone !== "" || data.policyHolderWorkPhone !== "" ? true : false,
                  "ok_to_text": data.policyHolderCellPhone !== "" ? true : false,
                  "ok_to_email": data.policyHolderEmail !== "" ? true : false,
                  "phones": [
                    {
                      "type": "HOME",
                      "number": data.policyHolderHomePhone
                    },
                    {
                      "type": "CELL",
                      "number": data.policyHolderCellPhone
                    },
                    {
                      "type": "WORK",
                      "number": data.policyHolderWorkPhone
                    },

                  ],
                  "emails": [
                    {
                      "type": "HOME",
                      "address": data.policyHolderEmail
                    }
                  ]
                },
                "license_info": {
                  "number": data.vehicleDriversLicenseNumber,
                  // "date": "2020-12-31", TODO
                  "state": data.vehicleDriversLicenseState
                },
                "is_passenger": data.sameAsVehicleOwner === "No" ? true : false,
                "is_driver": data.sameAsVehicleOwner === "Yes" ? true : false
              },
              "driver": {
                "first_name": data.sameAsVehicleOwner === "Yes" ? data.policyHolderFirstName : data.vehicleDriversFirstName,
                "last_name": data.sameAsVehicleOwner === "Yes" ? data.policyHolderLastName : data.vehicleDriversLastName,
                "address": {
                  "address": data.sameAsVehicleOwner === "Yes" ? data.policyHolderLastName : data.vehicleDriversStreetAddress,
                  "city": data.sameAsVehicleOwner === "Yes" ? data.policyHolderCity : data.vehicleDriversCity,
                  "state": data.sameAsVehicleOwner === "Yes" ? data.policyHolderState : data.vehicleDriversState,
                  "zip_code": data.sameAsVehicleOwner === "Yes" ? data.policyHolderZip : data.vehicleDriversZip
                },
                "medical_info": {
                  "date_of_birth": data.vehicleDriversDateOfBirth,
                  // "sex": "M", TODO???
                  "is_injured": data.wasDriverInjured === "Yes" ? true : false,
                  "injuries_description": data.driverInjuryDescription,
                  "sought_medical_treatment": data.didDriverSeekMedical === "Yes" ? true : false
                },
                "ticket_info": {
                  "is_issued": data.didDriverReceiveTicket === "Yes" ? true : false,
                  "reason": data.driverTicketReason
                },
                "contact_info": {
                  "ok_to_call": true,
                  "ok_to_text": true,
                  "ok_to_email": true,
                  "phones": [
                    {
                      "type": "HOME",
                      "number": data.sameAsVehicleOwner === "Yes" ? data.policyHolderHomePhone : data.vehicleDriversHomePhone
                    },
                    {
                      "type": "WORK",
                      "number": data.sameAsVehicleOwner === "Yes" ? data.policyHolderWorkPhone : data.vehicleDriversWorkPhone
                    },
                    {
                      "type": "CELL",
                      "number": data.sameAsVehicleOwner === "Yes" ? data.policyHolderCellPhone : data.vehicleDriversCellPhone
                    }
                  ],
                  "emails": [
                    {
                      "type": "HOME",
                      "address": data.sameAsVehicleOwner === "Yes" ? data.policyHolderEmail : data.vehicleDriversEmail
                    }
                  ]
                },
                "license_info": {
                  "number": data.vehicleDriversLicenseNumber,
                  // "date": "2020-12-31", TODO
                  "state": data.vehicleDriversLicenseState
                }
              },
              "passengers": [
                data?.passengers?.map((passenger) => (
                  {
                    "first_name": passenger.firstName,
                    "last_name": passenger.lastName,
                    "address": {
                      "address": passenger.streetAddress,
                      "city": passenger.city,
                      "state": passenger.state,
                      "zip_code": passenger.zip
                    },
                    "medical_info": {
                      "date_of_birth": passenger.dateOfBirth,
                      // "sex": "M",
                      "is_injured": passenger.wasPassengerInjured === "Yes" ? true : false,
                      "injuries_description": passenger.passengerInjuryDescription,
                      "sought_medical_treatment": passenger.didPassengerSeekMedical === "Yes" ? true : false
                    },
                    "contact_info": {
                      "ok_to_call": true,
                      "phones": [
                        {
                          "type": "HOME",
                          "number": passenger.homePhone
                        }
                      ],
                      // "emails": [
                      //   {
                      //     "type": "HOME",
                      //     "address": "test@example.com"
                      //   }
                      // ]
                    },
                    // "license_info": {
                    //   "number": 1234562,
                    //   "date": "2020-12-31",
                    //   "state": "IL"
                    // }
                  }

                ))
              ]
            }
          },
          "claimants": {
            "vehicles": data.isAnotherVehicleInvolved ? [
              {
                "year": data.otherVehicleYear,
                "make": data.otherVehicleMake,
                "model": data.otherVehicleModel,
                "vin": data.otherVehicleVehicleVin,
                "color": data.otherVehicleVehicleColor,
                "damage_info": {
                  "is_damaged": data.isOtherVehicleDamaged === "Yes" ? true : false,
                  "damage_description": data.otherVehicleDamageDescription,
                  "unrelated_prior_damage": data.otherVehicleUnrelatedPriorDamage,
                  "impact_location": data.otherVehicleInitialPointOfImpact
                },
                "license_plate": data.otherVehicleLicensePlateNumber,
                "is_drivable": data.isOtherVehicleDrivable === "Yes" ? true : false,
                "use_type": "", // MISSING TODO
                "garage_info": {
                  "location": {
                    "address": data.otherVehicleLocationStreetAddress,
                    "city": data.otherVehicleLocationCity,
                    "state": data.otherVehicleLocationState,
                    "zip_code": data.otherVehicleLocationZip
                  },
                  "name": data.OtherVehicleBusinessName,
                  "phone_number": data.OtherVehicleBusinessPhone
                },
                "owner": {
                  "first_name": data.otherVehicleOwnerFirstName,
                  "last_name": data.otherVehicleOwnerLastName,
                  "address": {
                    "address": data.otherVehicleOwnerStreetAddress,
                    "city": data.otherVehicleOwnerCity,
                    "state": data.otherVehicleOwnerState,
                    "zip_code": data.otherVehicleOwnerZip
                  },
                  "medical_info": {
                    "date_of_birth": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleDriversDateOfBirth : '',
                    // "sex": "M", TODO
                    "is_injured": data.otherVehicleWasDriverInjured === "Yes" ? true : false,
                    "injuries_description": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleDriverInjuryDescription : '',
                    "sought_medical_treatment": data.otherVehicleDidDriverSeekMedical === "Yes" ? true : false
                  },
                  "ticket_info": {
                    "is_issued": data.otherVehicleDidDriverReceiveTicket === "Yes" ? true : false,
                    "reason": data.otherVehicleDriverTicketReason
                  },
                  "contact_info": {
                    "ok_to_call": data.otherVehicleOwnerHomePhone || data.otherVehicleOwnerWorkPhone ? true : false,
                    "ok_to_text": data.otherVehicleOwnerCellPhone ? true : false,
                    "ok_to_email": data.otherVehicleOwnerEmail ? true : false,
                    "phones": [
                      {
                        "type": "HOME",
                        "number": data.otherVehicleOwnerHomePhone
                      },
                      {
                        "type": "WORK",
                        "number": data.otherVehicleOwnerWorkPhone
                      },
                      {
                        "type": "CELL",
                        "number": data.otherVehicleOwnerCellPhone
                      },
                    ],
                    "emails": [
                      {
                        "type": "HOME",
                        "address": data.otherVehicleOwnerEmail
                      }
                    ]
                  },
                  "license_info": {
                    "number": data.otherVehicleDriversLicenseNumber,
                    // "date": "2020-12-31", TODO
                    "state": data.otherVehicleDriversLicenseState
                  },
                  // "is_passenger": true,
                  // "is_driver": true
                },
                "driver": {
                  "first_name": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderFirstName : data.otherVehicleDriversFirstName,
                  "last_name": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderLastName : data.otherVehicleDriversLastName,
                  "address": {
                    "address": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderLastName : data.otherVehicleDriversStreetAddress,
                    "city": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderCity : data.otherVehicleDriversCity,
                    "state": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderState : data.otherVehicleDriversState,
                    "zip_code": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.policyHolderZip : data.otherVehicleDriversZip
                  },
                  "medical_info": {
                    "date_of_birth": data.otherVehicleDriversDateOfBirth,
                    // "sex": "M", TODO???
                    "is_injured": data.otherVehicleDriversInjured === "Yes" ? true : false,
                    "injuries_description": data.otherVehicleDriversInjuryDescription,
                    "sought_medical_treatment": data.otherVehicleDriversDidSeekMedical === "Yes" ? true : false
                  },
                  "ticket_info": {
                    "is_issued": data.otherVehicleDriversDidReceiveTicket === "Yes" ? true : false,
                    "reason": data.otherVehicleDriversTicketReason
                  },
                  "contact_info": {
                    "ok_to_call": true,
                    "ok_to_text": true,
                    "ok_to_email": true,
                    "phones": [
                      {
                        "type": "HOME",
                        "number": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleOwnerHomePhone : data.otherVehicleDriversHomePhone
                      },
                      {
                        "type": "WORK",
                        "number": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleOwnerWorkPhone : data.otherVehicleDriversWorkPhone
                      },
                      {
                        "type": "CELL",
                        "number": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleOwnerCellPhone : data.otherVehicleDriversCellPhone
                      }
                    ],
                    "emails": [
                      {
                        "type": "HOME",
                        "address": data.otherVehicleDriversSameAsVehicleOwner === "Yes" ? data.otherVehicleOwnerEmail : data.otherVehicleDriversEmail
                      }
                    ]
                  },
                  "license_info": {
                    "number": data.otherVehicleDriversLicenseNumber,
                    // "date": "2020-12-31",
                    "state": data.otherVehicleDriversLicenseState
                  }
                },
                "passengers": [
                  data?.passengersClaimant?.map((passenger) => (
                    {
                      "first_name": passenger.firstName,
                      "last_name": passenger.lastName,
                      "address": {
                        "address": passenger.streetAddress,
                        "city": passenger.city,
                        "state": passenger.state,
                        "zip_code": passenger.zip
                      },
                      "medical_info": {
                        "date_of_birth": passenger.dateOfBirth,
                        // "sex": "M",
                        "is_injured": passenger.wasPassengerInjured,
                        "injuries_description": passenger.passengerInjuryDescription,
                        "sought_medical_treatment": passenger.didPassengerSeekMedical === "Yes" ? true : false
                      },
                      "contact_info": {
                        "ok_to_call": passenger.homePhone ? true : false,
                        "phones": [
                          {
                            "type": "HOME",
                            "number": passenger.homePhone
                          }
                        ],
                        // "emails": [
                        //   {
                        //     "type": "HOME",
                        //     "address": "test@example.com"
                        //   }
                        // ]
                      },
                      // "license_info": {
                      //   "number": 1234562,
                      //   "date": "2020-12-31",
                      //   "state": "IL"
                      // }
                    }

                  ))
                ],
                "insurance_info": {
                  "name": data.OtherVehicleInsuranceCompanyName,
                  "policy_number": data.otherVehicleInsuranceCompanyPolicyNumber
                }
              }
            ] : [],
            "pedestrians": data.pedestriansInvolved === "Yes" ? [
              {
                "first_name": data.pedestriansFirstName,
                "last_name": data.pedestriansLastName,
                "address": {
                  "address": data.pedestriansStreetAddress,
                  "city": data.pedestriansCity,
                  "state": data.pedestriansState,
                  "zip_code": data.pedestriansZip
                },
                "medical_info": {
                  "date_of_birth": data.pedestrianDateOfBirth,
                  // "sex": "M",
                  "is_injured": data.pedestriansInjured === "Yes" ? true : false,
                  "injuries_description": data.pedestriansInjuryDescription,
                  "sought_medical_treatment": data.pedestrianDidSeekMedical === "Yes" ? true : false
                },
                // "ticket_info": {
                //   "is_issued": true,
                //   "reason": "Drunk driving"
                // },
                "contact_info": {
                  "ok_to_call": data.pedestriansHomePhone || data.pedestriansWorkPhone ? true : false,
                  "ok_to_text": data.pedestriansCellPhone ? true : false,
                  "ok_to_email": data.pedestriansEmail ? true : false,
                  "phones": [
                    {
                      "type": "HOME",
                      "number": data.pedestriansHomePhone
                    },
                    {
                      "type": "WORK",
                      "number": data.pedestriansWorkPhone
                    },
                    {
                      "type": "CELL",
                      "number": data.pedestriansCellPhone
                    }
                  ],
                  "emails": [
                    {
                      "type": "HOME",
                      "address": data.pedestriansEmail
                    }
                  ]
                },
                // "license_info": {
                //   "number": 1234562,
                //   "date": "2020-12-31",
                //   "state": "IL"
                // }
              }
            ] : [],
            "property_owners": data.fixedObjectInvolved === "Yes" && {
              "first_name": data.fixedObjectOwnersFirstName,
              "last_name": data.fixedObjectOwnersLastName,
              "address": {
                "address": data.fixedObjectOwnersStreetAddress,
                "city": data.fixedObjectOwnersCity,
                "state": data.fixedObjectOwnersState,
                "zip_code": data.fixedObjectOwnersZip
              },
              // "medical_info": {
              //   "date_of_birth": "2000-02-29",
              //   "sex": "M",
              //   "is_injured": true,
              //   "injuries_description": "Broken leg",
              //   "sought_medical_treatment": true
              // },
              // "ticket_info": {
              //   "is_issued": true,
              //   "reason": "Drunk driving"
              // },
              "contact_info": {
                "ok_to_call": data.fixedObjectOwnersHomePhone ? true : false,
                "ok_to_text": false,
                "ok_to_email": data.fixedObjectOwnersEmail ? true : false,
                "phones": [
                  {
                    "type": "HOME",
                    "number": data.fixedObjectOwnersHomePhone
                  }
                ],
                "emails": [
                  {
                    "type": "HOME",
                    "address": data.fixedObjectOwnersEmail
                  }
                ]
              },
              // "license_info": {
              //   "number": 1234562,
              //   "date": "2020-12-31",
              //   "state": "IL"
              // },
              "property_info": {
                "description": data.fixedObjectDescription,
                "damage_description": data.fixedObjectDamage,
                "address": {
                  "address": data.fixedObjectOwnersStreetAddress,
                  "city": data.fixedObjectOwnersCity,
                  "state": data.fixedObjectOwnersState,
                  "zip_code": data.fixedObjectOwnersZip
                }
              }
            }
          },
          "reporter": {
            "first_name": data.reporterFirstName,
            "last_name": data.reporterLastName,
            "type": data.insuredType !== "" ? data.insuredType : data.notInsuredType,
            "contact_info": {
              "phones": [
                {
                  "type": "HOME",
                  "number": data.reporterPhone
                }
              ],
              "emails": [
                {
                  "type": "HOME",
                  "address": data.reporterEmail
                }
              ]
            },
            // Are we missing this?
            "photo_estimating_info": {
              "can_contact": true,
              "phone_number": data.reporterPhone,
              "email_address": data.reporterEmail
            }
          },
          "loss_details": {
            "loss_date": data.dateOfAccident,
            "loss_time": data.timeOfAccident,
            "address": {
              "address": data.locationOfAccidentStreetAddress,
              "city": data.locationOfAccidentCity,
              "state": data.locationOfAccidentState,
              "zip_code": data.locationOfAccidentZip
            },
            "description": data.accidentDescription,
            "comments": data.additionalComments,
            "police_info": {
              "notified": data.policeNotified === "Yes" ? true : false,
              "case_number": data.policeReportNumber,
              "department_name": data.policeDepartment,
              "reported_method": data.reportFiled
            },
            "loss_type": data.damageType
          }
        } */

        data.emailTo = 'reed@thecollective.io'
        emailjs.send('service_95ghn9d', 'template_hv3b1f5', data, 'user_yjC6L95jqEW9krwJaHPTB')
          .then((result) => {
            console.log(result)
            setFormSubmitted(true);
          setFormSubmittedMessage(`Claim Submitted`);
          setFormSubmittedWarning('Please contact Unique Insurance +1-866-426-8842 for more information, and reference your claim number.');
          }, (error) => {
            console.log(error)
            setFormSubmitted(true);
            setFormError(true);
            formSubmittedWarning('An error has occurred, please contact Unique Insurance +1-866-426-8842');
          });

        // axios.put(`${rootUrl}/pnapi/claims/`, submittedData, {
        //   headers: {
        //     "x-api-key": xApiKey
        //   }
        // }).then(response => {
        //   setFormSubmitted(true);
        //   setFormSubmittedMessage(`Claim Number: ${response?.data?.claim_number}`);
        //   setFormSubmittedWarning('Please contact Unique Insurance +1-866-426-8842 for more information, and reference your claim number.');
        // }).catch((reason = AxiosError) => {
        //   setFormSubmitted(true);
        //   setFormError(true);
        //   setFormSubmittedMessage(reason?.message);
        //   formSubmittedWarning('An error has occurred, please contact Unique Insurance +1-866-426-8842');
        // });



      }
    },
    [],
  );

  return (
    <>
      {!formSubmitted ?
        (<form
          className={className}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Wizard form={form}>
            {screens.map(({ Component }, i) => (
              <Component form={form} key={i} />
            ))}
          </Wizard>
        </form>
        
        )
        :
        (

          <div>
            <h3>{formSubmittedMessage}</h3>
            <h3>{formSubmittedWarning}</h3>
          </div>
        )
      }
    </>
  );
};

export default Form;
