import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  pedestriansInvolved: yup.string().default('').required('This field is required'),
  pedestriansFirstName: yup.string().default(''),
  pedestriansMiddleName: yup.string().default(''),
  pedestriansLastName: yup.string().default(''),
  pedestriansStreetAddress: yup.string().default(''),
  pedestriansCity: yup.string().default(''),
  pedestriansState: yup.string().default(''),
  pedestriansZip: yup.string().default(''),
  pedestriansHomePhone: yup.string().default(''),
  pedestriansCellPhone: yup.string().default(''),
  pedestriansWorkPhone: yup.string().default(''),
  pedestriansEmail: yup.string().default('').email('Please enter a valid email address.'),
  pedestrianDateOfBirth: yup.string().default(''),
  pedestriansLicenseNumber: yup.string().default(''),
  pedestriansLicenseState: yup.string().default(''),
  pedestriansInjured: yup.string().default(''),
  pedestriansInjuryDescription: yup.string().default('').max(max, message),
  pedestrianDidSeekMedical: yup.string().default(''),
};

export default schema;
export { max };