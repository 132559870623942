import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';

import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {
  
  const sameAsVehicleOwner = form?.watch('sameAsVehicleOwner');
  const didDriverReceiveTicket = form?.watch('didDriverReceiveTicket');
  const wasDriverInjured = form?.watch('wasDriverInjured');

  const isInvalid = useMemo(
    () => (
      !sameAsVehicleOwner
    ),
    [
      sameAsVehicleOwner
    ],
  );

  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <>
      <Fieldset legend="Unique Driver Information">
        <Row>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Same as Vehicle Owner?"
              name="sameAsVehicleOwner"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {sameAsVehicleOwner === "No" &&
            (
              <>
                <Row>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.vehicleDriversFirstName}
                      id="vehicleDriversFirstName"
                      label="First name"
                      touched={form?.formState.touchedFields.vehicleDriversFirstName}
                      {...form?.register('vehicleDriversFirstName', {
                        onBlur: () => form?.trigger('vehicleDriversFirstName'),
                      })}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      id="vehicleDriversMiddleName"
                      label="Middle name"
                      {...form?.register('vehicleDriversMiddleName')}
                    />
                  </Col>
                  <Col xs={12} sm={6} md={4}>
                    <Input
                      autoComplete="off"
                      error={form?.formState.errors.vehicleDriversLastName}
                      id="vehicleDriversLastName"
                      label="Last name"
                      touched={form?.formState.touchedFields.vehicleDriversLastName}
                      {...form?.register('vehicleDriversLastName', {
                        onBlur: () => form?.trigger('vehicleDriversLastName'),
                      })}
                    />
                  </Col>
                </Row>

                <Row>
                <Col xs={12} sm={12}>
                  <Input
                    autoComplete="off"
                    id="vehicleDriversStreetAddress"
                    label="Street address"
                    {...form?.register('vehicleDriversStreetAddress')}
                  />
                  </Col>
                    <Col xs={12} sm={4}>
                      <Input
                        autoComplete="off"
                        id="vehicleDriversCity"
                        label="City"
                        {...form?.register('vehicleDriversCity')}
                      />
                    </Col>
                    <Col xs={12} sm={4}>
                      <Select
                        autoComplete="off"
                        id="vehicleDriversState"
                        label="State"
                        {...form?.register('vehicleDriversState')}
                      >
                        <option disabled value="" />
                        {usStates.map((state) => (
                          <option
                            key={state}
                            value={state}
                          >
                            {state}
                          </option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={12} sm={4}>
                      <Input
                        autoComplete="off"
                        id="vehicleDriversZip"
                        label="Zip Code"
                        {...form?.register('vehicleDriversZip')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="vehicleDriversHomePhone"
                        label="Home phone number"
                        type="tel"
                        {...form?.register('vehicleDriversHomePhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="vehicleDriversCellPhone"
                        label="Cell phone number"
                        type="tel"
                        {...form?.register('vehicleDriversCellPhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        id="vehicleDriversWorkPhone"
                        label="Work phone number"
                        type="tel"
                        {...form?.register('vehicleDriversWorkPhone')}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <Input
                        autoComplete="off"
                        error={form?.formState.errors.vehicleDriversEmail}
                        id="vehicleDriversEmail"
                        label="Email"
                        touched={form?.formState.touchedFields.vehicleDriversEmail}
                        type="email"
                        {...form?.register('vehicleDriversEmail', {
                          onBlur: () => form?.trigger('vehicleDriversEmail'),
                        })}
                      />
                    </Col>

                </Row>
              </>
            )}
        </Row>
        <Row>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              error={form?.formState.errors.vehicleDriversDateOfBirth}
              id="vehicleDriversDateOfBirth"
              label="Date of birth"
              type="date"
              {...form?.register('vehicleDriversDateOfBirth', {
                onBlur: () => form?.trigger('vehicleDriversDateOfBirth'),
                required: 'This field is required.',
              })}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Input
              autoComplete="off"
              id="vehicleDriversLicenseNumber"
              label="Drivers License Number"
              {...form?.register('vehicleDriversLicenseNumber')}
            />
          </Col>
          <Col xs={12} sm={6}>
            <Select
              autoComplete="off"
              id="vehicleDriversLicenseState"
              label="Drivers License State"
              {...form?.register('vehicleDriversLicenseState')}
            >
              <option disabled value="" />
              {usStates.map((state) => (
                <option
                  key={state}
                  value={state}
                >
                  {state}
                </option>
              ))}
            </Select>
          </Col>
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Did this person receive a ticket?"
              name="didDriverReceiveTicket"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
              <span value="Unknown">Unknown</span>
            </RadioGroup>
          </Col>
          {didDriverReceiveTicket === "Yes" &&
            <Col xs={12} sm={12}>
              <Textarea
                id="driverTicketReason"
                label="Ticket Reason"
                maxLength={maxLength}
                name="driverTicketReason"
                rows={3}
                form={form}
                {...form?.register('driverTicketReason')}
              />

            </Col>}
          <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Was this person injured?"
              name="wasDriverInjured"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          {wasDriverInjured ==="Yes" && 
          (
            <Row>
            <Col xs={12} sm={6}>
              <Textarea
                id="driverInjuryDescription"
                label="Describe Injuries"
                maxLength={maxLength}
                name="driverInjuryDescription"
                rows={3}
                form={form}
                {...form?.register('driverInjuryDescription')}
              />

            </Col>
            <Col xs={12} sm={6}>
            <RadioGroup
              form={form}
              label="Did they seek medical treatment?"
              name="didDriverSeekMedical"
            >
              <span value="Yes">Yes</span>
              <span value="No">No</span>
            </RadioGroup>
          </Col>
          </Row>

          )}
          <Col xs={12} sm={6}>
            <Select
              id="vehicleDriversPurposeOfUse"
              label="Purpose of Use"
              {...form?.register('vehicleDriversPurposeOfUse')}
            >
              <option disabled value="">Choose one</option>
              <option value="PERSONAL">Personal</option>
              <option value="BUSINESS">Business</option>
              <option value="RIDESHARE">Rideshare</option>
            </Select>
          </Col>

        </Row>

      </Fieldset>
    </>
  );
};

export default Screen;
