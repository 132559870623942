import * as yup from 'yup';

const schema = {
  vehicleYear: yup.string().default(''),
  vehicleMake: yup.string().default(''),
  vehicleModel: yup.string().default(''),
  licensePlateNumber: yup.string().default(''),
  vehicleColor: yup.string().default(''),
  vehicleVinNumber: yup.string().default(''),
};

export default schema;
