import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  sameAsVehicleOwner: yup.string().default('').required('This field is required'),
  vehicleDriversFirstName: yup.string().default(''),
  vehicleDriversMiddleName: yup.string().default(''),
  vehicleDriversLastName: yup.string().default(''),
  vehicleDriversStreetAddress: yup.string().default(''),
  vehicleDriversCity: yup.string().default(''),
  vehicleDriversState: yup.string().default(''),
  vehicleDriversZip: yup.string().default(''),
  vehicleDriversHomePhone: yup.string().default(''),
  vehicleDriversCellPhone: yup.string().default(''),
  vehicleDriversWorkPhone: yup.string().default(''),
  vehicleDriversEmail: yup.string().default('').email('Please enter a valid email address.'),
  vehicleDriversDateOfBirth: yup.string().default(''),
  vehicleDriversLicenseNumber: yup.string().default(''),
  vehicleDriversLicenseState: yup.string().default(''),
  didDriverReceiveTicket: yup.string().default(''),
  driverTicketReason: yup.string().default('').max(max, message),
  wasDriverInjured: yup.string().default(''),
  driverInjuryDescription: yup.string().default('').max(max, message),
  didDriverSeekMedical: yup.string().default(''),
  vehicleDriversPurposeOfUse: yup.string().default(''),
};

export default schema;
export { max };