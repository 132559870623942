import * as yup from 'yup';

const max = 1000;
const message = `Your vehicleDamageDescription may not be than ${max.toLocaleString()} characters.`;

const schema = {
  otherVehicleDriversSameAsVehicleOwner: yup.string().default(''),
  otherVehicleDriversFirstName: yup.string().default(''),
  otherVehicleDriversMiddleName: yup.string().default(''),
  otherVehicleDriversLastName: yup.string().default(''),
  otherVehicleDriversStreetAddress: yup.string().default(''),
  otherVehicleDriversCity: yup.string().default(''),
  otherVehicleDriversState: yup.string().default(''),
  otherVehicleDriversZip: yup.string().default(''),
  otherVehicleDriversHomePhone: yup.string().default(''),
  otherVehicleDriversCellPhone: yup.string().default(''),
  otherVehicleDriversWorkPhone: yup.string().default(''),
  otherVehicleDriversEmail: yup.string().default('').email('Please enter a valid email address.'),
  otherVehicleDriversDateOfBirth: yup.string().default(''),
  otherVehicleDriversLicenseNumber: yup.string().default(''),
  otherVehicleDriversLicenseState: yup.string().default(''),
  otherVehicleDriversDidReceiveTicket: yup.string().default(''),
  otherVehicleDriversTicketReason: yup.string().default('').max(max, message),
  otherVehicleDriversInjured: yup.string().default(''),
  otherVehicleDriversInjuryDescription: yup.string().default('').max(max, message),
  otherVehicleDriversDidSeekMedical: yup.string().default(''),
};

export default schema;
export { max };