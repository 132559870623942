import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import clsx from 'clsx';
import { noop } from 'lodash';
import InputWrapper from '../InputWrapper/InputWrapper';

const Select = forwardRef(({
  autoComplete,
  children,
  className,
  error,
  form,
  helperText,
  id,
  touched,
  label,
  name,
  onAutoComplete = noop,
  onBlur = noop,
  onChange = noop,
  ...rest
}, ref) => {
  const handleFauxIconClick = useCallback(() => form?.setFocus(name), [form, name]);

  const isAutoCompleteOff = useMemo(() => autoComplete === 'off', [autoComplete]);
  const [isAutoCompleted, setAutoCompleted] = useState(false);
  const isTouched = useMemo(() => (touched || isAutoCompleted), [isAutoCompleted, touched]);

  useEffect(() => {
    const handleAutoComplete = (e) => {
      if (isAutoCompleteOff || e.target.name !== name) return;

      if (e.target.hasAttribute('autocompleted')) {
        setAutoCompleted(true);
        setTimeout(onAutoComplete, 0);
        return;
      }

      setAutoCompleted(false);
    };

    document.addEventListener('onautocomplete', handleAutoComplete);
    return () => document.removeEventListener('onautocomplete', handleAutoComplete);
  }, [isAutoCompleteOff, name, onAutoComplete]);

  return (
    <InputWrapper
      className={className}
      error={error}
      helperText={helperText}
      label={label}
      labelHtmlFor={id}
      touched={isTouched}
    >
      <div
        className={clsx('Select', {
          'error': isTouched && error,
          'touched': isTouched,
        })}
      >
        <div className="Select__fauxIcon__wrapper">
          <select
            className="Select__select"
            id={id}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            ref={ref}
            {...(touched && { 'aria-invalid': error ? 'true' : 'false' })}
            {...rest}
          >
            {children}
          </select>
          <button
            aria-hidden="true"
            className="Select__fauxIcon"
            onClick={handleFauxIconClick}
            tabIndex={-1}
            type="button"
          />
          <span
            aria-hidden="true"
            className="Select__fauxBg"
          />
        </div>
      </div>
    </InputWrapper>
  );
});

Select.displayName = 'Select';

export default Select;
