import { useState, useEffect } from 'react';
import { get, has } from 'lodash';

const Helpers = {
  formatDate: (dateString) => {
    const options = { year: 'numeric', month: 'long' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  },

  trimString: (previewString, length = 140) => {
    if (previewString.length < length) return previewString;
    const trimmedString = previewString.substring(0, length);
    return `${trimmedString}&hellip;`;
  },

  preventOrphans: (string) => {
    if (!string) return string;
    const paragraphs =
      string
        .trim()
        .split('\n')
        .map((paragraph) => {
          const words = paragraph.trim().split(' ');
          if (words.length < 3) return paragraph;
          const lastWords = words.slice(-2).join('&nbsp;');
          return [...words.slice(0, words.length - 2), lastWords].join(' ');
        })
        .join('\n');
    return paragraphs;
  },

  // size = 'thumbnail', 'small', 'medium', 'large', 'full'
  selectImageSize: (featuredImage, size) => {
    const formats = get(featuredImage, 'formats');
    if (has(formats, size)) return formats[size].url;
    return featuredImage.url;
  },

  selectImageSizeObject: (featuredImage, size) => {
    const formats = get(featuredImage, 'formats');
    if (has(formats, size)) return formats[size];
    return featuredImage;
  },

  generateKey: (prefix) => `${prefix}_${new Date().getTime()}`,

  useWindowSize: () => {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
      width: undefined,
      // height: undefined,
    });

    useEffect(() => {
      // Handler to call on window resize
      const handleResize = () => (
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          // height: window.innerHeight,
        })
      );

      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Add event listener
      window.addEventListener('resize', handleResize);
      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    return windowSize;
  },

  getStrapiMedia: (url) => {
    if (!url) return null;
    // Return the full URL if the media is hosted on an external provider
    if (url.startsWith('http') || url.startsWith('//')) return url;
    // Otherwise prepend the URL path with the Strapi URL
    const API_URL = process.env.REACT_APP_STRAPI_API_URL || 'http://localhost:1337';
    return `${API_URL}${url}`;
  },
  linkChecker: (link) => {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return true;
    }
    else {
      return false;
    }
  }
};

export default Helpers;
