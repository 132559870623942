import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../../../../../Button/Button';
import Input from '../../../../../Input/Input';
import Textarea from '../../../../../Textarea/Textarea';
import emailjs from '@emailjs/browser';


const Form = ({ className }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');
  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      email: '',
      name: '',
      phone: '',
      linesBusiness: '',
      annualPremium: '',
      agencyName: '',
      states: '',
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      emailjs.send('service_95ghn9d', 'template_p0dyz9m', data, 'user_yjC6L95jqEW9krwJaHPTB')
      .then((result) => {
          setFormSubmitted(true);
          setFormSubmittedMessage('Thank you for applying we will be in touch with you soon!')
      }, (error) => {
          setFormSubmitted(true);
          setFormSubmittedMessage('There was an error submitting your form. Please refresh the page and try again.')
      });
    },
    [],
  );

  return (
    <>
    {!formSubmitted ? (<form
      className={className}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Row>
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.agencyName}
            id="agencyName"
            label="Agency Name*"
            touched={form.formState.touchedFields.agencyName}
            {...form.register('agencyName', {
              required: 'You must enter your agency name.',
            })}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.name}
            id="name"
            label="Primary Agency Contact Name*"
            touched={form.formState.touchedFields.name}
            {...form.register('name', {
              required: 'You must enter your primary agency contact name.',
            })}
          />
        </Col>
      </Row>
      <Row>
        
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.phone}
            id="phone"
            label="Phone Number*"
            touched={form.formState.touchedFields.phone}
            type="tel"
            {...form.register('phone', {
              required: 'You must enter your phone number.',
            })}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.email}
            id="email"
            label="Email*"
            touched={form.formState.touchedFields.email}
            type="email"
            {...form.register('email', {
              required: 'You must enter your email.',
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.linesBusiness}
            id="linesBusiness"
            label="Lines of Business*"
            touched={form.formState.touchedFields.linesBusiness}
            type="linesBusiness"
            {...form.register('linesBusiness', {
              required: 'You must enter your lines of business.',
            })}
          />
        </Col>
        <Col xs={12} md={6}>
          <Input
            error={form.formState.errors.annualPremium}
            id="annualPremium"
            label="Total Annual Premium*"
            touched={form.formState.touchedFields.annualPremium}
            type="annualPremium"
            {...form.register('annualPremium', {
              required: 'You must enter your total annual premium.',
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {(() => {
            const maxLength = 1000;

            return (
              <Textarea
                error={form.formState.errors.states}
                form={form}
                id="states"
                label="States that you operate in*"
                placeholder="i.e. California, Illinois, Florida ..."
                maxLength={maxLength}
                name="states"
                rows={10}
                touched={form.formState.touchedFields.states}
                {...form.register('states', {
                  maxLength: {
                    value: maxLength,
                    message: `Your states may not be than ${maxLength.toLocaleString()} characters.`,
                  },
                  required: 'You must enter a state.',
                })}
              />
            );
          })()}
        </Col>
      </Row>
      <Button
        type="submit"
        style={{ width: '100%' }}
      >
        Submit
      </Button>
    </form>) : (<h3>{formSubmittedMessage}</h3>)}
    </>
  );
};

export default Form;
