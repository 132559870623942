import axios, { AxiosError } from 'axios';
import React, { useCallback, useState,useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from '../../../../../Button/Button';
import Input from '../../../../../Input/Input';
import CreditCardForm from './CreditCardForm';


const PolicyForm = ({ className }) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSubmittedMessage, setFormSubmittedMessage] = useState('');
  const [policyFound, setPolicyFound] = useState(false);
  const [hasPayment, setHasPayment] = useState(false);
  // const [policyInformation, setPolicyInformation] = useState('')
  const [hashPolicy, setHashPolicy] = useState(window.location.hash.split('#')[1]);

  const [policyKey, setPolicyKey] = useState('');
  const [policyNumber, setPolicyNumber] = useState('');
  const rootUrl = process.env.REACT_APP_API_URL;
  const xApiKey = process.env.REACT_APP_X_API_KEY;

  useEffect(() => {
    if(hashPolicy) {
      onSubmit();
    }
  }, [])
  


  const { handleSubmit, ...form } = useForm({
    defaultValues: {
      policyNumber: '',
    },
  });

  const onSubmit = useCallback(
    async (data) => {
      if(hashPolicy) {
        var data = {};
        data.policyNumber = hashPolicy;
      }
      await axios.get(`${rootUrl}/pnapi/policy/${data.policyNumber}/billing/simple`, {
        headers: {
          "x-api-key": xApiKey
        }
      }).then(response => {
        setFormSubmittedMessage(response?.data?.payment_message);

        if (response?.data?.allow_payment) {

          axios.get(`${rootUrl}/pnapi/policy/${data.policyNumber}/billing/key`, {
            headers: {
              "x-api-key": xApiKey
            }
          }).then(response => {
            setPolicyNumber(data.policyNumber)
            setPolicyKey(response.data.public_key);
            setHasPayment(true);

          })
        } else {
          setFormSubmittedMessage(response?.data?.payment_message)
          setHasPayment(false);
        }

      }).catch((reason = AxiosError) => {
        
        setFormSubmittedMessage(reason?.response?.data?.message)
      })
    },
    [],
  );

  return (
    <>
      <form
        className={className}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
        <Col className="PolicyForm__message">
        <h3>{formSubmittedMessage}</h3>
        </Col>
          <Col xs={12} md={12}>
            <Input
              error={form.formState.errors.policyNumber}
              id="policyNumber"
              label="Policy Number*"
              touched={form.formState.touchedFields.policyNumber}
              disabled={hasPayment}
              value={window.location.hash.split('#')[1]}
              {...form.register('policyNumber', {
                required: 'You must enter your policy number.',
                
              })}
            />
          </Col>
        </Row>
        {!hasPayment && (<Button
          type="submit"
          style={{ width: '100%' }}
        >
          Submit
        </Button>
        )}
      </form>
      {hasPayment && <CreditCardForm policyKey={policyKey} policyNumber={policyNumber} />}
    </>
  );
};

export default PolicyForm;
