import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';

const Screen = ({ form, screenIndex, wizard }) => {
  
  const isAnotherVehicleInvolved = form?.watch('isAnotherVehicleInvolved');
  const isOtherVehicleDamaged = form?.watch('isOtherVehicleDamaged');
  const isOtherVehicleDrivable = form?.watch('isOtherVehicleDrivable');
  const isOtherVehicleBusinessAddress = form?.watch('isOtherVehicleBusinessAddress');
  const isInvalid = useMemo(
    () => (
      !isAnotherVehicleInvolved
    ),
    [
      isAnotherVehicleInvolved
    ],
  );
  useScreen({ isInvalid, screenIndex, wizard });

  return (
    <Fieldset legend="Other Vehicle Information">
      <Row>
        <Col xs={12} sm={12}>
          <RadioGroup
            id="isAnotherVehicleInvolved"
            form={form}
            label="Is there another Vehicle Involved?"
            name="isAnotherVehicleInvolved"
          >
            <span value="Yes">Yes</span>
            <span value="No">No</span>
          </RadioGroup>
        </Col>
        {isAnotherVehicleInvolved === "Yes" &&
          (
            <>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleYear"
                  label="Vehicle year"
                  {...form?.register('otherVehicleYear')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleMake"
                  label="Vehicle make"
                  {...form?.register('otherVehicleMake')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleModel"
                  label="Vehicle model"
                  {...form?.register('otherVehicleModel')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleVehicleColor"
                  label="Vehicle color"
                  {...form?.register('otherVehicleVehicleColor')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleLicensePlateNumber"
                  label="License plate number"
                  {...form?.register('otherVehicleLicensePlateNumber')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleVehicleVin"
                  label="Vehicle VIN Number"
                  {...form?.register('otherVehicleVehicleVin')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  form={form}
                  label="Is vehicle damaged?"
                  name="isOtherVehicleDamaged"
                  id="isOtherVehicleDamaged"
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {isOtherVehicleDamaged === "Yes" &&
                (
                  <Col xs={12} sm={6}>
                    <Textarea
                      id="otherVehicleDamageDescription"
                      label="Vehicle Damage"
                      maxLength={maxLength}
                      name="otherVehicleDamageDescription"
                      rows={3}
                      form={form}
                      {...form?.register('otherVehicleDamageDescription')}
                    />
                  </Col>

                )}
              <Col xs={12} sm={6}>
                <Textarea
                  id="otherVehicleInitialPointOfImpact"
                  label="Initial Point of Impact"
                  maxLength={maxLength}
                  name="otherVehicleInitialPointOfImpact"
                  rows={3}
                  form={form}
                  {...form?.register('otherVehicleInitialPointOfImpact')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Textarea
                  id="otherVehicleUnrelatedPriorDamage"
                  label="Unrelated Prior Damage"
                  maxLength={maxLength}
                  name="otherVehicleUnrelatedPriorDamage"
                  rows={3}
                  form={form}
                  {...form?.register('otherVehicleUnrelatedPriorDamage')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  form={form}
                  label="Is the Vehicle Drivable?"
                  name="isOtherVehicleDrivable"
                  id="isOtherVehicleDrivable"
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {isOtherVehicleDrivable === "No" &&
                (
                  <Fieldset legend="Where is the vehicle Located?">
                    <Input
                      autoComplete="off"
                      id="otherVehicleLocationStreetAddress"
                      label="Street address"
                      {...form?.register('otherVehicleLocationStreetAddress')}
                    />
                    <Row>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id="otherVehicleLocationCity"
                          label="City"
                          {...form?.register('otherVehicleLocationCity')}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Select
                          autoComplete="off"
                          id="otherVehicleLocationState"
                          label="State"
                          {...form?.register('otherVehicleLocationState')}
                        >
                          <option disabled value="" />
                          {usStates.map((state) => (
                            <option
                              key={state}
                              value={state}
                            >
                              {state}
                            </option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id="otherVehicleLocationZip"
                          label="Zip Code"
                          {...form?.register('otherVehicleLocationZip')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={4}>
                        <RadioGroup
                          form={form}
                          label="Is This a Business?"
                          name="isOtherVehicleBusinessAddress"
                          id="isOtherVehicleBusinessAddress"
                        >
                          <span value="Yes">Yes</span>
                          <span value="No">No</span>
                        </RadioGroup>
                      </Col>
                      {isOtherVehicleBusinessAddress === "Yes" &&
                        (
                          <>
                            <Col xs={12} sm={4}>
                              <Input
                                autoComplete="off"
                                id="OtherVehicleBusinessName"
                                label="Business Name"
                                {...form?.register('OtherVehicleBusinessName')}
                              />
                            </Col>
                            <Col xs={12} sm={4}>
                              <Input
                                autoComplete="off"
                                id="OtherVehicleBusinessPhone"
                                type="tel"
                                label="Business Phone"
                                {...form?.register('OtherVehicleBusinessPhone')}
                              />
                            </Col>
                          </>
                        )}
                    </Row>
                  </Fieldset>
                )}
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="OtherVehicleInsuranceCompanyName"
                  label="Insurance Company Name"
                  {...form?.register('OtherVehicleInsuranceCompanyName')}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id="otherVehicleInsuranceCompanyPolicyNumber"
                  label="Insurance Company Policy Number"
                  {...form?.register('otherVehicleInsuranceCompanyPolicyNumber')}
                />
              </Col>
            </>
          )}
      </Row>
    </Fieldset>
  );
};

export default Screen;
