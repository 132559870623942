import React, { useEffect } from 'react';
// import * as yup from 'yup';
import ordinal from 'ordinal';
import { Col, Row } from 'react-bootstrap';
import { range } from 'lodash';
import { useFieldArray } from 'react-hook-form';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import RadioGroup from '../../../../../../../../RadioGroup/RadioGroup';
import Select from '../../../../../../../../Select/Select';
import usStates from '../../../us_states.json';
import useScreen from '../hooks/useScreen';
import { max as maxLength } from './schema';
import Textarea from '../../../../../../../../Textarea/Textarea';

const Screen = ({ form, screenIndex, wizard }) => {
  const numberOfClaimantsValue = form?.watch('numberOfClaimants');
  const claimantsValue = form?.watch('claimants');

  // intentionally not memoizing this variable.
  // doing so disables validation (unknown as to why).
  const isInvalid =
    !numberOfClaimantsValue ||
    !!claimantsValue
      .map(({ firstName, lastName }) => (!firstName || !lastName))
      .filter(Boolean)
      .length;

  useScreen({ isInvalid, screenIndex, wizard });

  const { fields, append, remove } = useFieldArray({
    control: form?.control,
    name: 'claimants',
  });

  useEffect(() => {
    const newVal = parseInt(numberOfClaimantsValue || 0);
    const oldVal = fields.length;

    if (newVal > oldVal) {
      for (let i = oldVal; i < newVal; i++) {
        append({
          firstName: '',
          lastName: '',
          streetAddress: '',
          city: '',
          state: '',
          zip: '',
          homePhone: '',
          workPhone: '',
          cellPhone: '',
          email: '',
          dateOfBirth: '',
          wasClaimantMinor: '',
          parentFirstName: '',
          parentLastName: '',
          parentStreetAddress: '',
          parentCity: '',
          parentState: '',
          parentZip: '',
          parentHomePhone: '',
          parentWorkPhone: '',
          parentCellPhone: '',
          parentEmail: '',
          insuranceCompanyName: '',
          insurancePolicyNumber: '',
          propertyDamageDescription: '',
          relationshipToInsured: '',
          wasClaimantInjured: '',
          claimantInjuryDescription: '',
          didClaimantSeekMedical: ''
        });
      }
      return;
    }

    for (let i = oldVal; i > newVal; i--) {
      remove(i - 1);
    }
  }, [fields, numberOfClaimantsValue]);

  return (
    <>
      <RadioGroup
        error={form?.formState.errors.numberOfClaimants}
        form={form}
        id="numberOfClaimants"
        name="numberOfClaimants"
        label="For how many people is a liability or Medical Payments claim being submitted?"

      >
        {range(5).map((i) => (
          <span key={i} value={i}>{i}</span>
        ))}
      </RadioGroup>
      {fields.map((field, i) => (
        <React.Fragment key={field.id}>
          <span aria-hidden="true" />
          <Fieldset legend={`${ordinal(i + 1)} Claimant&rsquo;s Information`}>
            <Row>
              <Col xs={12} sm={6} md={6}>
                <Input
                  autoComplete="off"
                  error={form?.formState.errors.claimants?.[i]?.firstName}
                  id={`firstName-${field.id}`}
                  label="First name"
                  touched={form?.formState.touchedFields.claimants?.[i]?.firstName}
                  {...form?.register(`claimants[${i}].firstName`, {
                    onBlur: () => form?.trigger('claimants'),
                  })}
                />
              </Col>

              <Col xs={12} sm={6} md={6}>
                <Input
                  autoComplete="off"
                  error={form?.formState.errors.claimants?.[i]?.lastName}
                  id={`lastName-${field.id}`}
                  label="Last name"
                  touched={form?.formState.touchedFields.claimants?.[i]?.lastName}
                  {...form?.register(`claimants[${i}].lastName`, {
                    onBlur: () => form?.trigger('claimants'),
                  })}
                />
              </Col>
            </Row>
            <Input
              autoComplete="off"
              id={`streetAddress-${field.id}`}
              label="Street address"
              {...form?.register(`claimants[${i}].streetAddress`)}
            />
            <Row>
              <Col xs={12} sm={4}>
                <Input
                  autoComplete="off"
                  id={`city-${field.id}`}
                  label="City"
                  {...form?.register(`claimants[${i}].city`)}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Select
                  autoComplete="off"
                  id={`state-${field.id}`}
                  label="State"
                  {...form?.register(`claimants[${i}].state`)}
                >
                  <option disabled value="" />
                  {usStates.map((state) => (
                    <option
                      key={state}
                      value={state}
                    >
                      {state}
                    </option>
                  ))}
                </Select>
              </Col>
              <Col xs={12} sm={4}>
                <Input
                  autoComplete="off"
                  id={`zip-${field.id}`}
                  label="Zip Code"
                  {...form?.register(`claimants[${i}].zip`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`homePhone-${field.id}`}
                  label="Home phone number"
                  type="tel"
                  {...form?.register(`claimants[${i}].homePhone`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`workPhone-${field.id}`}
                  label="Work phone number"
                  type="tel"
                  {...form?.register(`claimants[${i}].workPhone`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`cellPhone-${field.id}`}
                  label="Cell phone number"
                  type="tel"
                  {...form?.register(`claimants[${i}].cellPhone`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`email-${field.id}`}
                  label="Email Address"
                  type="tel"
                  {...form?.register(`claimants[${i}].email`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`dateOfBirth-${field.id}`}
                  label="Date of Birth"
                  type="date"
                  {...form?.register(`claimants[${i}].dateOfBirth`)}
                />
              </Col>
              <Col xs={12} sm={6}>

                <RadioGroup
                  form={form}
                  id={`wasClaimantMinor-${field.id}`}
                  name={`wasClaimantMinor-${field.id}`}
                  label="Was this person Minor?"
                  onChange={(e) => form?.setValue(`claimants[${i}].wasClaimantMinor`, e.target.value)}
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {form?.getValues(`wasClaimantMinor-${field.id}`) === "Yes" &&
                (
                  <Fieldset legend="PARENT/GUARDIAN">
                    <Row>
                      <Col xs={12} sm={6} md={6}>
                        <Input
                          autoComplete="off"
                          id={`parentFirstName-${field.id}`}
                          label="First name"
                          {...form?.register(`claimants[${i}].parentFirstName`)}
                        />
                      </Col>

                      <Col xs={12} sm={6} md={6}>
                        <Input
                          autoComplete="off"
                          id={`parentLastName-${field.id}`}
                          label="Last name"
                          {...form?.register(`claimants[${i}].parentLastName`)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12} sm={12}>
                        <Input
                          autoComplete="off"
                          id={`parentStreetAddress-${field.id}`}
                          label="Street address"
                          {...form?.register(`claimants[${i}].parentStreetAddress`)}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id={`parentCity-${field.id}`}
                          label="City"
                          {...form?.register(`claimants[${i}].parentCity`)}
                        />
                      </Col>
                      <Col xs={12} sm={4}>
                        <Select
                          autoComplete="off"
                          id={`parentState-${field.id}`}
                          label="State"
                          {...form?.register(`claimants[${i}].parentState`)}
                        >
                          <option disabled value="" />
                          {usStates.map((state) => (
                            <option
                              key={state}
                              value={state}
                            >
                              {state}
                            </option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={12} sm={4}>
                        <Input
                          autoComplete="off"
                          id={`parentZip-${field.id}`}
                          label="Zip Code"
                          {...form?.register(`claimants[${i}].parentZip`)}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Input
                          autoComplete="off"
                          id={`parentHomePhone-${field.id}`}
                          label="Home phone number"
                          type="tel"
                          {...form?.register(`claimants[${i}].parentHomePhone`)}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Input
                          autoComplete="off"
                          id={`parentWorkPhone-${field.id}`}
                          label="Work phone number"
                          type="tel"
                          {...form?.register(`claimants[${i}].parentWorkPhone`)}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Input
                          autoComplete="off"
                          id={`parentCellPhone-${field.id}`}
                          label="Cell phone number"
                          type="tel"
                          {...form?.register(`claimants[${i}].parentCellPhone`)}
                        />
                      </Col>
                      <Col xs={12} sm={6}>
                        <Input
                          autoComplete="off"
                          id={`parentEmail-${field.id}`}
                          label="Email Address"
                          type="tel"
                          {...form?.register(`claimants[${i}].parentEmail`)}
                        />
                      </Col>
                    </Row>
                  </Fieldset>
                )}
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`insuranceCompanyName-${field.id}`}
                  label="Insurance Company Name"
                  {...form?.register(`claimants[${i}].insuranceCompanyName`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Input
                  autoComplete="off"
                  id={`insurancePolicyNumber-${field.id}`}
                  label="Insurance Policy Number"
                  {...form?.register(`claimants[${i}].insurancePolicyNumber`)}
                />
              </Col>
              <Col xs={12} sm={6}>
                <Textarea
                  id={`propertyDamageDescription-${field.id}`}
                  label="Describe Property Damage Involved"
                  maxLength={maxLength}
                  name={`propertyDamageDescription-${field.id}`}
                  rows={3}
                  form={form}
                  {...form?.register(`claimants[${i}].propertyDamageDescription`)}
                />
              </Col>
              <Col xs={12} sm={4}>
                <Select
                  autoComplete="off"
                  id={`relationshipToInsured-${field.id}`}
                  label="Relationship to the Insured"
                  {...form?.register(`claimants[${i}].relationshipToInsured`)}
                >
                  <option disabled value="" selected>Please select a relationship</option>
                  <option value="Resident Relative">Resident Relative</option>
                  <option value="Non Resident Relative">Non Resident Relative</option>
                  <option value="Resident Household Member">Resident Household Member</option>
                  <option value="Non Resident">Non Resident</option>
                </Select>
              </Col>
              <Col xs={12} sm={6}>
                <RadioGroup
                  form={form}
                  id={`wasClaimantInjured-${field.id}`}
                  name={`wasClaimantInjured-${field.id}`}
                  label="Was this person injured?"
                  onChange={(e) => form?.setValue(`claimants[${i}].wasClaimantInjured`, e.target.value)}
                >
                  <span value="Yes">Yes</span>
                  <span value="No">No</span>
                  <span value="Unknown">Unknown</span>
                </RadioGroup>
              </Col>
              {form?.getValues(`wasClaimantInjured-${field.id}`) === "Yes" &&
                (
                  <Row>
                    <Col xs={12} sm={6}>
                      <Textarea
                        id={`claimantInjuryDescription-${field.id}`}
                        label="Describe Injuries"
                        maxLength={maxLength}
                        name={`claimantInjuryDescription-${field.id}`}
                        rows={3}
                        form={form}
                        {...form?.register(`claimants[${i}].claimantInjuryDescription`)}
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <RadioGroup
                        id={`didClaimantSeekMedical-${field.id}`}
                        form={form}
                        label="Did this person seek medical treatment?"
                        name={`didClaimantSeekMedical-${field.id}`}
                        onChange={(e) => form?.setValue(`claimants[${i}].didClaimantSeekMedical`, e.target.value)}
                      >
                        <span value="Yes">Yes</span>
                        <span value="No">No</span>
                        <span value="Unknown">Unknown</span>
                      </RadioGroup>
                    </Col>
                  </Row>
                )}
            </Row>
          </Fieldset>
        </React.Fragment>
      ))}
    </>
  );
};

export default Screen;
