import React, { useState } from 'react';
import clsx from 'clsx';
import parse from 'html-react-parser';
import { Container, Row, Col } from 'react-bootstrap';
import useHeaderAs from '../../../hooks/useHeaderAs';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';
import PolicyForm from './components/PolicyForm';
import Markdown from 'markdown-to-jsx';
import DividerBlock from '../../ContentBlocks/DividerBlock/DividerBlock';
import Helpers from '../../../../../utils/Helpers';
import Button from '../../../../Button/Button';
// import CreditCardForm from './components/CreditCardForm';

const PaymentFormBlock = ({ data, className, ...rest }) => {
  // TODO: wire up strapi to return options for `white`, `black`
  const [ref, headerAs] = useHeaderAs('h1', 'h2');
  const isVisible = useIsBlockVisible(data, ['header', 'displayForm']);

  if (!isVisible) return;

  return (
    <section
      className={clsx('PaymentFormBlock__wraper', className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="PaymentFormBlock">
          {data.header && (
            <div className="PaymentFormBlock__header__wrapper">
              {headerAs && (() => {
                const Header = headerAs;
                return (
                  <Header className="PaymentFormBlock__header">
                    {parse(data.header)}
                  </Header>
                );
              })()}

            </div>

          )}
          {data.body && (
            <Markdown
              className="PaymentFormBlock__body"
              options={{ forceBlock: true }}
            >
              {Helpers.preventOrphans(data.body)}
            </Markdown>
          )}
          {data.displayForm && <PolicyForm className="PaymentFormBlock__form" />}
          <Row>
          <Col xs={12} md={6}>
            <a
              className="Button Button__Grey PaymentFormBlock__button"
              href="https://policyholder.uniqueinsuranceco.com/login"
              target="_blank"
            >
              Personal Auto Login
            </a>

          </Col>
          <Col xs={12}  md={6} className="mt-sm-5 mt-5 mt-md-0">
            <a
              className="Button Button__Grey PaymentFormBlock__button"
              href="https://policyholder.myuniqueins.com/login"
              target="_blank"
            >
              Manufactured Homeowner Login
            </a>

          </Col>
          </Row>
          <Col xs={12}>
            <p className="PaymentFormBlock__small__text">*The payment balance may include an installment fee and any late payment fees if applicable. A $25 fee will be assessed for any checks returns for insufficient funds. Payment administered by Unique Insurance Managers</p>
          </Col>

        </div>
      </Container>
    </section>
  );
};

export default PaymentFormBlock;
