import React, { useState } from 'react';
import clsx from 'clsx';
import { Container } from 'react-bootstrap';
import Modal from '../../../../Modal/Modal';
import useHeaderAs from '../../../hooks/useHeaderAs';
import useIsBlockVisible from '../../../hooks/useIsBlockVisible';
import HeaderParagraphBlock from '../../ContentBlocks/HeaderParagraphBlock/HeaderParagraphBlock';
import Form from './components/Form';

const ClaimFormBlock = ({ data, className, ...rest }) => {
  const endpoint = data.endpoint;
  
  const [ref, headerAs] = useHeaderAs('h1', 'h2');
  const [isFormVisible, setFormVisibility] = useState(false);
  const isComponentVisible = useIsBlockVisible(data, ['header', 'body', 'buttonText', 'displayForm']);
  var loginText = '';
  var loginLink = '';
  if (!isComponentVisible) return;

  if(endpoint==="personal-auto" ) {
    loginText="Personal Auto Login";
    loginLink="https://policyholder.uniqueinsuranceco.com/login"
  } else {
    loginText="Manufactured Homeowner Login"
    loginLink="https://policyholder.myuniqueins.com/login"
  }
  return (
    <section
      className={clsx('ClaimFormBlock__wrapper', className)}
      ref={ref}
      {...rest}
    >
      <Container>
        <div className="ClaimFormBlock">
          <HeaderParagraphBlock
            as="div"
            data={{
              body: data.body,
              buttonText: data.buttonText,
              disabled: !data.displayForm,
              header: data.header,
              loginButton: true,
              loginText: loginText,
              loginLink: loginLink,
              onClick: () => setFormVisibility(true),
            }}
            headerAs={headerAs}
          />
          {(data.buttonText && data.displayForm) && (
            <Modal
              isCloseButtonVisible={false}
              panelClassName="ClaimFormBlock__modal__panel"
              onClose={() => setFormVisibility(false)}
              open={isFormVisible}
              title={data.buttonText}
              titleClassName="ClaimFormBlock__modal__title"
            >
              <Form
                className="ClaimFormBlock__form"
                endpoint={data.endpoint}
              />
            </Modal>
          )}
        </div>
      </Container>
    </section>
  );
};

export default ClaimFormBlock;
