import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Fieldset from '../../../../../../../../Fieldset/Fieldset';
import Input from '../../../../../../../../Input/Input';
import useScreen from '../hooks/useScreen';

const Screen = ({ form, screenIndex, wizard }) => {
  useScreen({ isInvalid: false, screenIndex, wizard });

  return (
    <Fieldset legend="Unique&rsquo;s Insured Vehicle Information">
      <Row>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleYear"
            label="Vehicle year"
            {...form?.register('vehicleYear')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleMake"
            label="Vehicle make"
            {...form?.register('vehicleMake')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleModel"
            label="Vehicle model"
            {...form?.register('vehicleModel')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleColor"
            label="Vehicle color"
            {...form?.register('vehicleColor')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="licensePlateNumber"
            label="License plate number"
            {...form?.register('licensePlateNumber')}
          />
        </Col>
        <Col xs={12} sm={6}>
          <Input
            autoComplete="off"
            id="vehicleVinNumber"
            label="Vehicle VIN Number"
            {...form?.register('vehicleVinNumber')}
          />
        </Col>
      </Row>
    </Fieldset>
  );
};

export default Screen;
