import React, { useEffect, useState } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import axios from 'axios';
import { trackPromise } from 'react-promise-tracker';
import network from './utils/Network';
import DynamicRouter from './utils/DynamicRouter';
import LoadingIndicator from './components/LoadingIndicator/LoadingIndicator';

const App = () => {
  const [appData, setAppData] = useState(null);

  useEffect(() => {
    trackPromise(
      axios
        .all([
          axios.get(network.apiBase + 'announcement'),
          axios.get(network.apiBase + 'footer?populate=*'),
          // axios.get(network.apiBase + 'main-menus?populate=deep'),
          axios.get(network.apiBase + 'map-datas?pagination[limit]=50&populate=*'),
          axios.get(network.apiBase + 'pages?pagination[limit]=50&populate=deep'),
        ])
        .then(
          axios.spread((
            announcement,
            footer,
            // mainMenus,
            mapDatas,
            pages,
          ) => (
            setAppData({
              announcement: announcement.data.data,
              footer: footer.data.data,
              // mainMenus: mainMenus.data.data,
              mapDatas: mapDatas.data.data,
              pages: pages.data.data,
            })
          ))
        )
        .catch((error) => {
          // TODO:
          // show some sort of an unstyled error screen if we get here
          console.log('error on main data pull:');
          console.log(error);
        })
    );

    AOS.init({ duration: 600 });

    // AOS.refresh();
  }, []);

  return (
    <div className="app">
      <LoadingIndicator hasLogo={true} />
      {appData && (
        <DynamicRouter
          announcement={appData.announcement}
          footer={appData.footer}
          // mainMenus={appData.mainMenus}
          mapDatas={appData.mapDatas}
          pages={appData.pages}
        />
      )}
    </div>
  );
}

export default App;
